import { captureException } from "@sentry/nextjs";
import { IPlan } from "./types";
import { AllPlanSlugType, PlanSlugType } from "@common/config/plan/types";
import { PLAN_STARTER } from "@common/config/plan/constants";

export function getRawPlanAlias(planAlias: AllPlanSlugType): PlanSlugType {
  return planAlias
    .replace("-cloud", "")
    .replace("-self-hosted", "") as PlanSlugType;
}

export function isDWHPlan(slug: string): boolean {
  return slug.includes("-with-dwh");
}

export function isSelfHostedPlan(slug: string): boolean {
  return !(slug.includes("-cloud") || slug.includes(PLAN_STARTER));
}

export function isYearlyPlan(slug: string): boolean {
  return slug.includes("-yearly");
}

// billing frequency

export function isMonthlyPlan({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 1);
}

export function isAnnualPlan({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 12);
}

export function getBillingFrequencyCopy({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): string {
  if (billing_period_months === 1) {
    return "Monthly Billing";
  } else if (billing_period_months === 12) {
    return "Annual Billing";
  }

  captureException(
    new Error(
      `getBillingFrequencyCopy error: unknown billing frequency of "${billing_period_months}"`,
    ),
  );
  return "Unknown Billing Frequency";
}

export function formatPlanName(name: string): string {
  return name.replace("Metabase", "").trim();
}
