export function getSelectedSection(
  sections: string[],
  targetSection: string,
): string | null {
  const selectedSections = sections
    .map((sectionName) =>
      sectionName === targetSection ||
      (targetSection &&
        sectionName.includes("*") &&
        targetSection.indexOf(sectionName.replace("*", "")) === 0)
        ? targetSection
        : null,
    )
    .filter((tab) => !!tab);

  const [selectedTab] =
    selectedSections && selectedSections.length > 0 ? selectedSections : [];

  return selectedTab || null;
}

export function getSelectorFromSection(
  section: string,
  accordionId?: string,
): string {
  return accordionId === section
    ? `[id=${section}]` // accordeon
    : `[id*=-${section}]`; // section
}
