import { Accordion } from "@mantine/core";
import { useRouter } from "next/router";
import { PropsWithChildren, ReactElement } from "react";
import { ACCORDION_SECTION_VALUE } from "../AccordionSection/constants";
import { IAccordionCustomProps } from "./types";
import { ACCORDION_TRANSITION_DURATION } from "./constants";
import { useGetCurrentSection, useScrollToSection } from "./hooks";

export default function AccordionCustom({
  id,
  sections = [],
  defaultSection,
  children,
}: PropsWithChildren<IAccordionCustomProps>): ReactElement {
  const router = useRouter();

  // scroll
  const currentSection = useGetCurrentSection(id, sections);
  useScrollToSection(currentSection, defaultSection, id);

  return (
    <div id={id}>
      <Accordion
        id={id}
        transitionDuration={ACCORDION_TRANSITION_DURATION}
        defaultValue={currentSection || defaultSection}
        value={currentSection || defaultSection}
        onChange={(value) => {
          if (value && value !== ACCORDION_SECTION_VALUE) {
            router.replace({
              hash: `section=${value}`,
            });
          }
        }}
      >
        {children}
      </Accordion>
    </div>
  );
}
