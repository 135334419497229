import { useCallback, useEffect, useState } from "react";
import { ACCORDION_TRANSITION_DURATION } from "./constants";
import { getSelectedSection, getSelectorFromSection } from "./utils";
import { useRouter } from "next/router";
import { getHashParams } from "@common/utils/router";

export function useGetCurrentSection(
  accordionId?: string,
  sections: string[] = [],
): string | null | undefined {
  const router = useRouter();
  const hash = getHashParams(router.asPath);
  const { section } = hash;

  const [currentSection, setCurrentSection] = useState<string | null>();

  useEffect(() => {
    let timeoutId;
    const formattedSection = String(section);

    // focus on accordeon
    if (accordionId === formattedSection) {
      timeoutId = setTimeout(() => {
        setCurrentSection(formattedSection);
      }, 100);
    }
    // focus on section
    else {
      const selectedSection = getSelectedSection(sections, formattedSection);
      timeoutId = setTimeout(() => {
        setCurrentSection(selectedSection);
      }, 100);
    }

    return () => clearTimeout(timeoutId);
  }, [accordionId, section, sections]);

  return currentSection;
}

export function useScrollToSection(
  currentSection?: string | null,
  defaultSection?: string | null,
  accordionId?: string,
) {
  const getCurrentSection = useCallback(
    (currentSection: string, accordionId?: string) => {
      const currentSectionSelector = getSelectorFromSection(
        currentSection,
        accordionId,
      );
      return document.querySelector(currentSectionSelector);
    },
    [],
  );

  const scrollToSection = useCallback(($section: Element) => {
    return setTimeout(() => {
      const $mainHeader = document.querySelector("#main-header");
      const { height: mainHeaderHeight } =
        $mainHeader?.getBoundingClientRect() || { height: 0 };
      const offsetY = Math.ceil(mainHeaderHeight / 100) * 100;

      const { top: bodyY } = document.body.getBoundingClientRect();
      const { top: sectionY } = $section.getBoundingClientRect();
      scrollTo({ top: sectionY - bodyY - offsetY, behavior: "smooth" });
    }, ACCORDION_TRANSITION_DURATION + 50);
  }, []);

  useEffect(() => {
    let timeoutId;

    const goToSection = currentSection
      ? // go to current section
        defaultSection !== currentSection
        ? currentSection
        : null
      : // go to default section
        defaultSection || null;

    if (goToSection) {
      const $currentSection = getCurrentSection(goToSection, accordionId);
      if ($currentSection) {
        timeoutId = scrollToSection($currentSection);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [
    accordionId,
    defaultSection,
    getCurrentSection,
    currentSection,
    scrollToSection,
  ]);
}
